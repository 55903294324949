@layer reset, textbox, ui, storyblok;
@font-face {
font-family: '__efCircular_4cb8f7';
src: url(/_next/static/media/5c58e7c515d0d83d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__efCircular_4cb8f7';
src: url(/_next/static/media/c35b3917cfec7745-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__efCircular_4cb8f7';
src: url(/_next/static/media/4cc752bee216158e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__efCircular_4cb8f7';
src: url(/_next/static/media/964109c1bc1d7abc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__efCircular_4cb8f7';
src: url(/_next/static/media/c950505d4381934a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__efCircular_4cb8f7';
src: url(/_next/static/media/ecc8f823e98cced8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__efCircular_4cb8f7';
src: url(/_next/static/media/909075b0f0c8600d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__efCircular_4cb8f7';
src: url(/_next/static/media/d8d7a9e95fe5bb3c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__efCircular_Fallback_4cb8f7';src: local("Arial");ascent-override: 102.59%;descent-override: 33.25%;line-gap-override: 0.00%;size-adjust: 102.25%
}.__className_4cb8f7 {font-family: '__efCircular_4cb8f7', '__efCircular_Fallback_4cb8f7'
}

